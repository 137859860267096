var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap vgm_pop vgm_area",
      staticStyle: { width: "550px", "max-height": "600px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v("Request Repair Restitution"),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("table", { staticClass: "tbl_col" }, [
            _vm._m(0),
            _c("tbody", [
              _c("tr", [
                _c("th", [_vm._v("Bank")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.regParams.bnkNm,
                        expression: "regParams.bnkNm",
                      },
                    ],
                    attrs: { type: "text", id: "bnkNm" },
                    domProps: { value: _vm.regParams.bnkNm },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.regParams, "bnkNm", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v("Account No.")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.regParams.acctNo,
                        expression: "regParams.acctNo",
                      },
                    ],
                    attrs: { type: "text", id: "acctNo" },
                    domProps: { value: _vm.regParams.acctNo },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.regParams, "acctNo", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v("Account Name.")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.regParams.acctNm,
                        expression: "regParams.acctNm",
                      },
                    ],
                    attrs: { type: "text", id: "acctNm" },
                    domProps: { value: _vm.regParams.acctNm },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.regParams, "acctNm", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v("Email")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.regParams.emlAddr,
                        expression: "regParams.emlAddr",
                      },
                    ],
                    attrs: { type: "text", id: "emlAddr" },
                    domProps: { value: _vm.regParams.emlAddr },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.regParams, "emlAddr", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v(_vm._s(_vm.$t("msg.ONEX050G020.019")))]),
                _vm._v(" "),
                _c("td", { staticClass: "text_left" }, [
                  _c("span", [
                    _c("div", { staticClass: "div-file" }, [
                      _c("button", { on: { click: _vm.handleInputDivClick } }, [
                        _vm._v(_vm._s(_vm.$t("msg.ONEX050G020.023"))),
                      ]),
                      _c("span", { staticStyle: { "padding-right": "10px" } }, [
                        _vm._v(_vm._s(_vm.regParams.fileNm)),
                      ]),
                    ]),
                    _c("input", {
                      ref: "uploadFile",
                      staticStyle: { width: "0", height: "0" },
                      attrs: { type: "file", id: "inpFile" },
                      on: {
                        change: function ($event) {
                          return _vm.fnFileUpload()
                        },
                      },
                    }),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "mt10 text_center" }, [
          _c(
            "a",
            {
              staticClass: "button blue lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.saveRepairRestitution()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX050G020.027")))]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "button gray lg",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("msg.ONEX050G020.028")))]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "20%" } }),
      _c("col", { attrs: { width: "80%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }