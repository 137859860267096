<template>
  <div class="popup_wrap vgm_pop vgm_area" style="width:550px; max-height:600px;">
    <button class="layer_close" @click="$emit('close')">close</button>
    <div class="popup_cont">
      <!-- popup_cont -->
      <h1 class="page_title">Request Repair Restitution</h1>
      <div class="content_box">
        <table class="tbl_col">
          <colgroup>
            <col width="20%">
            <col width="80%">
          </colgroup>
          <tbody>
            <tr>
              <th>Bank</th>
              <td>
                <input type="text" v-model="regParams.bnkNm" id="bnkNm">
              </td>
            </tr>
            <tr>
              <th>Account No.</th>
              <td>
                <input type="text" v-model="regParams.acctNo" id="acctNo">
              </td>
            </tr>
            <tr>
              <th>Account Name.</th>
              <td>
                <input type="text" v-model="regParams.acctNm" id="acctNm">
              </td>
            </tr>
            <tr>
              <th>Email</th>
              <td>
                <input type="text" v-model="regParams.emlAddr" id="emlAddr">
              </td>
            </tr>
            <tr>
              <th>{{ $t('msg.ONEX050G020.019') }}</th> <!-- 파일 -->
              <td class="text_left">
                <!--  탐색기 나오는 파일 첨부일 경우 사용
                  <span class="filebox">
                  <input class="upload-name" value="">
                  <label for="file">파일첨부</label>
                  <input type="file" id="file">
                </span> -->

                <span>
                  <div class="div-file">
                    <button @click="handleInputDivClick">{{ $t('msg.ONEX050G020.023') }}</button>
                    <span style="padding-right: 10px;">{{ regParams.fileNm }}</span>
                  </div>
                  <input ref="uploadFile" @change="fnFileUpload()" type="file" id="inpFile" style="width: 0; height: 0;" />
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div><!-- content_box // -->
      <div class="mt10 text_center">
        <a class="button blue lg" href="#" @click.prevent="saveRepairRestitution()">{{ $t('msg.ONEX050G020.027') }}</a> <!-- 저장 -->
        <a class="button gray lg" href="#" @click.prevent="$emit('close')">{{ $t('msg.ONEX050G020.028') }}</a> <!-- 취소 -->
      </div>
    </div><!-- popup_cont -->
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import bookings from '@/api/rest/trans/bookings'
import commons from '@/api/services/commons'
import CalcIssue from '@/api/rest/settle/calcIssue'

export default ({
  name: 'CalcIssueRepairRestitutionPop',
  components: {
    CalcIssue,
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'vgm-upload-pop': () => import('@/pages/trans/popup/VgmUploadPop')
  },
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          blNo: '',
          bankList: []
        }
      }
    }
  },
  data () {
    return {
      saveFlag: false,
      formData: {
        blNo: '',
        methCd: '',
        uiFlag: '',
        type: '',
        vgmList: [],
        viewType: '',
        bkgList: [],
        cntrList: [],
        //파일 정보
        uploadFileInfos: [],
        rawCntrWtList: []
      },
      customComponent: null,
      popupParams: [],
      uploadParams: [],
      tempFile: {
        id: '',
        msg: ''
      },
      filename: '',
      regParams: {
        blNo: '',
        bnkNm: '',
        acctNo: '',
        acctNm: '',
        emlAddr: '',
        fileId: '',
        fileNm: '',
        mrRno: '',
        fileChange: false
      }
    }
  },
  computed: {
    ...rootComputed
  },
  created () {
    //세션값 셋팅
    if (this.auth !== undefined) {
      this.lang = this.$ekmtcCommon.isNotEmpty(this.auth.serviceLang) ? this.auth.serviceLang : 'KOR'
    }

    this.regParams.blNo = this.parentInfo.blNo
    this.regParams.bnkNm = this.parentInfo.bnkNm
    this.regParams.acctNo = this.parentInfo.acctNo
    this.regParams.acctNm = this.parentInfo.acctNm
    this.regParams.emlAddr = this.parentInfo.emlAddr
    this.regParams.fileNm = this.parentInfo.fileNm
    this.regParams.reqRno = this.parentInfo.reqRno
  },
  methods: {
    valEngCheck (e) {
      //let vNormal = /[^a-zA-Z.~`!@#$%^&*()_+=|?\\'\\"?/><,-\\[\]{};:^\s]/g
      let vNormal = /[^a-zA-Z^\s]/g
      if (vNormal.test(e.target.value) && e.target.value) {
        this.$ekmtcCommon.alertDefault('Input should be in English')
        //this.$ekmtcCommon.onlyEngValue(e)
        e.target.value = e.target.value.replace(vNormal, '')
      }
    },
    //파일첨부 클릭
    fnFileUpload () {
      //file 타입 태그 클릭 이벤트 -> change이벤트 호출 -> 임시저장 -> excel upload 클릭시 file아이디 보내서 받은 것을 바이트스트림으로 변환후 받기
      const formData = new FormData()
      const inpFile = document.querySelector('#inpFile')

      formData.append('files', inpFile.files[0])
      this.filename = inpFile.files[0].name
      this.regParams.fileNm = inpFile.files[0].name

      commons.tempFileUpload(formData).then((result) => {
          const fileId = result.data.fileId
          this.tempFile.id = fileId
          this.tempFile.msg = 'SELECT * FROM CM530I WHERE FILE_ID = ' + fileId + ';'
          this.regParams.fileChange = true
      })
      .catch(err => {
        console.log(err)
      })
    },
    handleInputDivClick () {
      this.$refs.uploadFile.click()
    },
    async saveRepairRestitution () {
      let inputIdList = ['#bnkNm', '#acctNo', '#acctNm', '#emlAddr']
      let isOk = true

      for (const id of inputIdList) {
        let val = $(id).val()
        if (this.$ekmtcCommon.isEmpty(val)) {
          this.$ekmtcCommon.showErrorTooltip(id, this.$t('msg.CSBK100.147'))
          isOk = false
        } else {
          this.$ekmtcCommon.hideErrorTooltip(id)
        }
      }

      if (isOk) {
        this.regParams.blNo = this.parentInfo.blNo
        this.regParams.fileId = this.tempFile.id
        this.regParams.fileCont = ''

        if (!this.saveFlag) {
          this.saveFlag = true
          await CalcIssue.insertRepairRestitutionList(this.regParams).then(response => {
            this.$ekmtcCommon.alertDefault('Completed to save.')
            this.$emit('close')
            this.saveFlag = false
          }).catch(e => {
            console.log(e)
            this.saveFlag = false
          })
        }
      }
    }
  }
})
</script>
<style scoped>
.div-file {
  display: inline-block;
}
.div-file > button {
  display: inline-block;
}
</style>
